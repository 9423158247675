<nav class="navbar navbar-expand-md site-nav" [ngClass]="{'fixed-top': pinTopNav}">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="">
      <img class="logo-shadow" src="assets/images/logosite.png" alt="Logo" height="60"
        class="d-inline-block align-text-top">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
      aria-controls="offcanvasNavbar" aria-expanded="false" aria-label="Toggle navigation">
      <fa-icon [icon]="faBars"></fa-icon>
    </button>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
      <div class="offcanvas-body">
        <a class="offcanvas-close" data-bs-dismiss="offcanvas">
          <fa-icon [icon]="faXMark"></fa-icon>
        </a>
        <div class="menu-title py-1 px-2">{{'app.ui.nav.menu' | translate }}</div>
        <ul class="navbar-nav me-auto">
          <li class="nav-item px-2 py-1">
            <a class="nav-link" routerLink="news" data-bs-dismiss="offcanvas" [routerLinkActive]="['active']">{{
              'app.ui.nav.news' |
              translate }}</a>
          </li>
          <li class="nav-item px-2 py-1">
            <a class="nav-link" routerLink="members" data-bs-dismiss="offcanvas" [routerLinkActive]="['active']">
              {{ 'app.ui.nav.members' | translate }}
            </a>
          </li>
          <li class="nav-item px-2 py-1">
            <a class="nav-link" routerLink="interclub" data-bs-dismiss="offcanvas" [routerLinkActive]="['active']">
              {{ 'app.ui.nav.interclub' | translate }}
            </a>
          </li>
          <!--<li class="nav-item px-2 py-1">
            <a class="nav-link" routerLink="tournaments" data-bs-dismiss="offcanvas" [routerLinkActive]="['active']">
              {{ 'app.ui.nav.tournaments' | translate }}
            </a>
          </li>
          <li class="nav-item px-2 py-1">
            <a class="nav-link" routerLink="rankings" data-bs-dismiss="offcanvas" [routerLinkActive]="['active']">
              {{ 'app.ui.nav.rankings' | translate }}
            </a>
          </li>-->
        </ul>
        <div class="menu-title py-1 px-2">{{'app.ui.nav.districts' | translate }}</div>
        <ul class="navbar-nav ms-auto">
          <li id="filter-nav" class="nav-item px-2 py-1 dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <span>{{ selectedGlobalFilter }}</span>
            </a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" data-bs-dismiss="offcanvas" (click)="removeGlobalSiteDistrictFilter()">{{
                  "app.ui.nav.all-districts" | translate }}</a></li>
              <hr class="text-white">
              <li><a class="dropdown-item" data-bs-dismiss="offcanvas"
                  (click)="setGlobalSiteDistrictFilterToAntwerp()">{{ "app.ui.nav.antwerp" | translate }}</a></li>
              <li><a class="dropdown-item" data-bs-dismiss="offcanvas"
                  (click)="setGlobalSiteDistrictFilterToLimburg()">{{ "app.ui.nav.limburg" | translate }}</a></li>
              <li><a class="dropdown-item" data-bs-dismiss="offcanvas"
                  (click)="setGlobalSiteDistrictFilterToEastFlanders()">{{ "app.ui.nav.east-flanders" | translate }}</a>
              </li>
              <li><a class="dropdown-item" data-bs-dismiss="offcanvas"
                  (click)="setGlobalSiteDistrictFilterToFlemishBrabant()">{{ "app.ui.nav.flemish-brabant" | translate
                  }}</a></li>
              <li><a class="dropdown-item" data-bs-dismiss="offcanvas"
                  (click)="setGlobalSiteDistrictFilterToWestFlanders()">{{ "app.ui.nav.west-flanders" | translate }}</a>
              </li>
              <hr class="text-white">
              <li><a class="dropdown-item" data-bs-dismiss="offcanvas"
                  (click)="setGlobalSiteDistrictFilterToHainault()">{{ "app.ui.nav.hainault" | translate }}</a></li>
              <!-- <li><a class="dropdown-item" data-bs-dismiss="offcanvas"
                  (click)="setGlobalSiteDistrictFilterToLiege()">{{ "app.ui.nav.liege" | translate }}</a></li> -->
            </ul>
          </li>
          <div class="menu-title py-1 px-2">{{'app.ui.nav.administration' | translate }}</div>
          <ng-container *ngIf="auth.isAuthenticated$ | async; else loggedOut">
            <li id="user-nav" class="nav-item px-2 py-1 dropdown no-arrow" *ngIf="auth.user$ | async as user">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                <span class="me-2 d-inline text-white">{{ userFullName }}</span>
                <img class="img-profile rounded-circle" title="profile-pic" src="{{ profilePicturePath }}" />
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <a class="dropdown-item" data-bs-dismiss="offcanvas" [routerLink]="['profile']">{{ 'app.nav.profile' |
                    translate }}</a>
                </li>
                <li>
                  <a *ngIf="currentUser" class="dropdown-item" data-bs-dismiss="offcanvas"
                    [routerLink]="['profile', 'settings', currentUser.id]">{{
                    'app.nav.settings' | translate }}</a>
                </li>
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li>
                  <a class="dropdown-item" data-bs-dismiss="offcanvas" routerLink="authenticated/gameform">{{
                    'app.nav.gameform' | translate
                    }}</a>
                </li>
                <li *ngIf="isAdmin">
                  <hr class="dropdown-divider">
                </li>
                <li *ngIf="isAdmin">
                  <a class="dropdown-item" data-bs-dismiss="offcanvas" [routerLink]="['admin']">{{ 'app.nav.admin' |
                    translate }}</a>
                </li>
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li>
                  <a class="dropdown-item" data-bs-dismiss="offcanvas" (click)="logout()">{{
                    "application.navigation.logout" | translate
                    }}</a>
                </li>
              </ul>
            </li>
          </ng-container>
          <ng-template #loggedOut>
            <li class="nav-item px-2 py-1">
              <a class="nav-link login-link" (click)="auth.loginWithRedirect()">
                {{ "app.ui.nav.login" | translate }}
              </a>
            </li>
          </ng-template>
        </ul>
      </div>
    </div>
  </div>
</nav>