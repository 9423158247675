export const environment = {
  production: true,
  appVersion: require('../../package.json').version,
  environment: 'production',
  consentDomain: 'snookervlaanderen.be',
  settings: {
    documentBase: 'https://api.snookervlaanderen.be',
    serviceBase: 'https://api.snookervlaanderen.be/api/',
    graphQLBase: 'https://api.snookervlaanderen.be/graphql/'
  },
  auth0: {
    domain: 'snookervlaanderen.eu.auth0.com',
    audience: 'https://api.snookervlaanderen.be',
    clientId: 'sPtQZI7ItmhlNyKeLYdyV6LLI02V4LDg',
    redirectUri: 'https://test.snookervlaanderen.be/callback',
  }
};
