import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { LOCATION_INITIALIZED } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateParser, TranslateService } from '@ngx-translate/core';
import { CustomMissingTranslationHandler } from '@translation/missing-translation-handler';
import { TranslationLoader } from '@translation/translation-loader';
import { CookieService } from 'ngx-cookie-service';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiBaseInterceptor } from './core/interceptors/api-base.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { CheckboxModule } from 'primeng/checkbox';

import * as moment from 'moment';
import { DisableControlDirective } from './core/directives/disable-control.directive';
import { CacheInterceptor } from './core/interceptors/cache.interceptor';
import { SharedModule } from './shared/shared.module';
import { GraphQLModule } from './graphql.module';
import { MasterDataService } from './core/services/masterdata.service';
import { MasterdataRepository } from './store/masterdata/masterdata.repository';
import { lastValueFrom } from 'rxjs';

export const LANGUAGE_COOKIE = 'SNVL-UI-LANG';
export const DISTRICT_SITE_FILTER_COOKIE = 'SVNL-UI-DISTRICT-SITE-FILTER';
const ALLOWED_LANGS = ["nl-BE", "fr-BE", "de-BE", "en-BE"]

@NgModule({
  declarations: [
    AppComponent,
    DisableControlDirective
  ],
  imports: [
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        audience: environment.auth0.audience,
        redirect_uri: environment.auth0.redirectUri
      },
      httpInterceptor: {
        allowedList: [
          { uri: `${environment.settings.serviceBase}*`, allowAnonymous: true },
        ]
      }
    }),
    NgcCookieConsentModule.forRoot({
      cookie: {
        domain: environment.consentDomain,
        expiryDays: 90
      },
      theme: 'edgeless',
      type: 'opt-out',
      position: 'bottom',
      palette: {
        popup: {
          background: "#000000",
          text: "#ffffff",
          link: "#ffffff"
        },
        button: {
          background: "#f1d600",
          text: "#000000",
          border: "transparent"
        }
      },
      content: {
        href: "https://cookiesandyou.com",
      }
    }),
    AppRoutingModule,
    BreadcrumbModule,
    BrowserModule,
    BrowserAnimationsModule,
    ButtonModule,
    CheckboxModule,
    DialogModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    TableModule,
    ToastModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: (MissingTranslationFactory),
        deps: [HttpClient, TranslateParser]
      }
    }),
    GraphQLModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, CookieService, Injector],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiBaseInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    MessageService
  ],
  exports: [TranslateModule, TableModule,
    ButtonModule],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslationLoader(http);
}

export function MissingTranslationFactory(http: HttpClient, parser: TranslateParser) {
  return new CustomMissingTranslationHandler(http, parser);
}

export function appInitializerFactory(translate: TranslateService, cookieService: CookieService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(async () => {
      let defaultLanguage = 'nl-BE';
      const masterDataService = injector.get(MasterDataService);
      const masterDataRepository = injector.get(MasterdataRepository);

      let districts = await lastValueFrom(masterDataService.getAllDistrictsByCountryCode('BE'));
      masterDataRepository.updateDistricts(districts);

      if (cookieService.check(LANGUAGE_COOKIE)) {
        defaultLanguage = cookieService.get(LANGUAGE_COOKIE);
      } else {
        let browserLang = translate.getBrowserCultureLang()!;

        if (ALLOWED_LANGS.includes(browserLang)) {
          defaultLanguage = browserLang;
        }

        cookieService.set(LANGUAGE_COOKIE, defaultLanguage);
      }

      translate.addLangs(ALLOWED_LANGS);
      moment.locale(defaultLanguage.toLowerCase());

      translate.use(defaultLanguage).subscribe(
        {
          next: () => {
            console.debug(`Successfully initialized '${defaultLanguage}' language.'`);
          },
          error: _err => {
            console.error(_err);
            console.error(`Problem with '${defaultLanguage}' language initialization.'`);
          },
          complete: () => resolve(null)
        });
    });
  });
}
